import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/:id?",
        name: "home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    },
    {
        path: "/onboarding/:id?",
        name: "onboarding",
        component: () =>
            import(
        /* webpackChunkName: "onboarding" */ "../views/OnboardingView.vue"
            ),
    },
    {
        path: "/finished/:id?/:diaId?",
        name: "finished",
        component: () =>
            import(/* webpackChunkName: "finished" */ "../views/FinishedView.vue"),
    },
    {
        path: "/continueonpc/:id?/:diaId?",
        name: "continueonpc",
        component: () =>
            import(/* webpackChunkName: "continueonpc" */ "../views/ContinueOnPcView.vue"),
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: () =>
            import(
        /* webpackChunkName: "onboarding" */ "../views/UnauthorizedView.vue"
            ),
    },
    {
        path: "/simulation",
        name: "simulation",
        component: () => import(/* webpackChunkName: "simulation" */ "../views/SimulateOnboarding.vue"),
    },
    {
        path: "/qr/:id?",
        name: "qr",
        component: () => import(/* webpackChunkName: "qr" */ "../views/QrView.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
