<template>
    <FirmatechHeader />
    <template v-if="$isMobile()">
        <div class="container px-3 mt-3">
            <router-view />
        </div>
    </template>
    <template v-else>
        <QrView />
    </template>
    <FirmatechFooter />
</template>

<script>
import FirmatechHeader from '@/components/FirmatechHeader.vue';
import FirmatechFooter from '@/components/FirmatechFooter.vue';
import QrView from '@/views/QrView.vue';

export default {
    name: 'App',
    components: {
        FirmatechHeader,
        FirmatechFooter,
        QrView,
    },
    created() {
        //console.log(this.$isMobile());
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

@font-face {
    font-family: Lato-Regular;
    src: url('@/assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: Lato-Bold;
    src: url('@/assets/fonts/Lato-Bold.ttf');
}

@font-face {
    font-family: Lato-Black;
    src: url('@/assets/fonts/Lato-Black.ttf');
}

@import url('@/assets/css/main.css');
@import 'animate.css';
</style>
<!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script> -->
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet"
    integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
</link>