<template>
    <div class="mt-5 lato-bold text-center px-1 pt-5">
        Para utilizar esta app, escanee el c&oacute;digo QR desde un dispositivo m&oacute;vil
    </div>
    <div class="mt-3">
        <vue-qrcode :value="baseUrl" type="image/png" :width="400" :color="qrColor" />
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'; // Asegúrate de que el importe es correcto para la librería que estás usando.
import { ref, onMounted } from 'vue';

export default {
    name: 'QrView',
    components: {
        VueQrcode,
    },
    setup() {
        const baseUrl = ref("");
        const qrColor = ref({
            dark: "#000000",
            light: "#ffffff"
        });

        onMounted(() => {
            console.log("Checking route and setting base URL");
            const location = window.location.href.replace(/\/(qr|onboarding|finished|unauthorized)/g, "");
            console.log({ location });
            baseUrl.value = location;
            console.log("baseUrl", baseUrl.value);
        });

        return {
            baseUrl,
            qrColor
        };
    }
}
</script>


// codigo original de vue2
<!-- <template>
    <div class="mt-5 lato-bold text-center px-1 pt-5">
        Para utilizar esta app, escanee el c&oacute;digo QR desde un dispositivo m&oacute;vil
    </div>
    <div class="mt-3">
        <vue-qrcode :value="baseUrl" type="image/png" :width="400" :color="QrColor" />
    </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
    name: 'QrView',
    components: {
        VueQrcode,
    },
    data() {
        return {
            baseUrl: "",
            QrColor: {
                dark: "#000000",
                light: "#ffffff"
            }
        }
    },
    created() {
        console.log("router", this.$route)
        const location = window.location.href.replace("/qr", "").replace("/onboarding", "").replace("/finished", "").replace("/unauthorized", "");
        console.log({ location })
        this.baseUrl = location;
        console.log("baseUrl", this.baseUrl);
    }
}
</script> -->
